// @flow
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';
import Page from '../components/Page';
import { useSiteMetadata } from '../hooks';
import type { MarkdownRemark } from '../types';

type Props = {
  data: {
    markdownRemark: MarkdownRemark
  }
};

const articles = [
	{
		"issue": "heise Developer",
		"year": 2019,
		"month": 7,
		"title": "Features von übermorgen: Worker Threads in Node.js",
		"url": "https://heise.de/-4354189",
		"excerpt": "Seit Version 10.5 stellt Node.js sogenannte Worker Threads als experimentelles Feature zur Verfügung. Diese Blogartikel stellt das Feature kurz vor.",
		"tags": ["JS", "Node.js"]
	},
	{
		"issue": "heise Developer",
		"year": 2018,
		"month": 6,
		"title": "Tools & Libraries: Kommunikation mit Web-Workern",
		"url": "https://heise.de/-4063928",
		"excerpt": "Web Worker eignen sich hervorragend dazu, komplexe Berechnungen vom Haupt-Thread einer Webanwendung auszulagern und damit die Performance einer Anwendung zu verbessern. Eine interessante Bibliothek, die die Kommunikation zwischen Worker-Threads und Haupt-Thread vereinfacht, ist Comlink.",
		"tags": ["JS"]
	},
	{
		"issue": "heise Developer",
		"year": 2017,
		"month": 10,
		"title": "Features von übermorgen: die Payment Request API",
		"url": "https://www.heise.de/-3694012",
		"excerpt": "Das Implementieren von Bestell- beziehungsweise Bezahlprozessen innerhalb von Webanwendungen kann mitunter recht komplex sein. Die sogenannte Payment Request API, die momentan beim W3C ausgearbeitet wird und vor etwa einem Monat zusammen mit den Payment Method Identifiers als \"Candidate Recommendation\" veröffentlicht wurde, soll hier Abhilfe schaffen.",
		"tags": ["JS"]
	},
	{
		"issue": "heise Developer",
		"year": 2017,
		"month": 5,
		"title": "Multi-Package-Repositories mit Lerna",
		"url": "https://heise.de/-3718109",
		"excerpt": "Ein wesentliches Design-Prinzip unter Node.js ist es, den Code getreu dem Motto \"small is beautiful\" in möglichst kleine, wiederverwendbare Packages zu strukturieren. Bei komplexeren Projekten kann das jedoch schnell unübersichtlich werden, wenn für jedes Package ein eigenes Git-Repository zu verwalten ist. Das Tool \"Lerna\" verspricht Abhilfe.",
		"tags": ["JS"]
	},
	{
		"issue": "heise Developer",
		"year": 2017,
		"month": 2,
		"title": "Features von übermorgen: die Shape Detection API",
		"url": "https://heise.de/-3622853",
		"excerpt": "Bei der Web Incubator Community Group werden verschiedene Vorschläge für neue Web APIs diskutiert und ausgearbeitet. Eine davon ist die Shape Detection API, mithilfe derer sich über den Browser verschiedene Formen wie Gesichter oder Barcodes innerhalb von Bilddaten erkennen lassen.",
		"tags": ["JS"]
	},
	{
		"issue": "heise Developer",
		"year": 2016,
		"month": 12,
		"title": "Features von übermorgen: die Web Share API und die Web Share Target API",
		"url": "http://heise.de/-3506197",
		"excerpt": "Bislang gibt es für das Teilen von Inhalten zwischen mobilen Webanwendungen keinen einheitlichen Ansatz. Doch dies könnte sich bald ändern, denn bei der Web Incubator Community Group macht man sich bereits Gedanken über entsprechende Web APIs: Über die Web Share API soll das Teilen von Inhalten möglich sein, über die Web Share Target API das Empfangen von Inhalten.",
		"tags": ["JS"]
	},
	{
		"issue": "heise Developer",
		"year": 2016,
		"month": 10,
		"title": "Features von übermorgen: die Async Cookies API",
		"url": "http://heise.de/-3357752",
		"excerpt": "Die Schnittstelle, um in JavaScript mit Cookies zu arbeiten, hat mindestens zwei Probleme: Zum einen ist der Zugriff auf Cookies nicht gerade komfortabel, zum anderen ist die Synchronität der Operationen rund um document.cookie nicht mehr zeitgemäß. Dagegen will die Async Cookies API Abhilfe schaffen.",
		"tags": ["JS"]
	},
	{
		"issue": "heise Developer",
		"year": 2016,
		"month": 8,
		"title": "Konferenzüberblick: Kommende Veranstaltungen zum Thema Webentwicklung in 2016",
		"url": "http://heise.de/-3304722",
		"excerpt": "Nach der Sommerpause stehen hierzulande wie in den vergangenen Jahren auch wieder einige interessante Veranstaltungen vor der Tür, die sich mit allen wichtigen Themen rund um die Webentwicklung beschäftigen.",
		"tags": ["JS", "CSS", "HTML"]
	},
	{
		"issue": "heise Developer",
		"year": 2016,
		"month": 8,
		"title": "Features von übermorgen: die Font Loading API",
		"url": "http://heise.de/-3278867",
		"excerpt": "Die Font Loading API bietet einen standardisierten Weg, um Schriften dynamisch per JavaScript zu laden. Dadurch macht sie die bisherigen Vorgehen überflüssig.",
		"tags": ["JS"]
	},
	{
		"issue": "heise Developer",
		"year": 2016,
		"month": 6,
		"title": "Ich kenne was, was Du nicht kennst: stacktrace.js",
		"url": "http://heise.de/-3237452",
		"excerpt": "Die Bibliothek stacktrace.js ermöglicht den browserunabhängigen Zugriff auf Stacktrace-Informationen und bezieht Lokalisierungsinformationen aus Source Maps mit ein, um von der minifizierten Version Rückschlüsse auf Zeilen- und Spaltennummer im Originalcode ziehen zu können.",
		"tags": ["JS"]
	},
	{
		"issue": "heise Developer",
		"year": 2016,
		"month": 5,
		"title": "Features von übermorgen: die Generic Sensor API",
		"url": "http://heise.de/-3209540",
		"excerpt": "Das Einbeziehen von Sensorinformationen wird auch bei der Entwicklung von Webanwendungen immer wichtiger. In den vergangenen Jahren haben sich beim W3C verschiedene Spezifikationen angesammelt, in denen Schnittstellen zu bestimmten Arten von Sensoren beschrieben werden, beispielsweise die Device Orientation API, die Geolocation API, die Proximity Events API oder die Ambient Light Sensor API.",
		"tags": ["JS"]
	},
	{
		"issue": "heise Developer",
		"year": 2016,
		"month": 4,
		"title": "Features von übermorgen: die Web Bluetooth API",
		"url": "http://heise.de/-3167796",
		"excerpt": "Das Thema Bluetooth wird zunehmend auch für den Bereich der Webentwicklung interessant. Während es für mobile (native bzw. hybride) Anwendungen bereits relativ viele Möglichkeiten gibt, per Bluetooth auf andere Geräte zuzugreifen (beispielsweise via PhoneGap-Plug-ins, die für den Nutzer transparent bleiben), ist das aus dem Browser heraus nur über Plug-ins möglich, die der Nutzer selbst installieren muss (z.B. Brulee SDK). Hier soll die Web Bluetooth API Abhilfe schaffen.",
		"tags": ["JS"]
	},
	{
		"issue": "heise Developer",
		"year": 2016,
		"month": 3,
		"title": "GoF-Entwurfsmuster in JavaScript, Teil 1: Singleton",
		"url": "http://heise.de/-3079815",
		"excerpt": "Die Entwurfsmuster der Gang of Four sind eine Sammlung bewährter Lösungen zu häufig auftretenden Problemstellungen, soweit dürfte allgemein bekannt sein. Doch wie sieht es mit der Relevanz dieser Entwurfsmuster für die Sprache JavaScript aus?",
		"tags": ["JS"]
	},
	{
		"issue": "heise Developer",
		"year": 2016,
		"month": 2,
		"title": "Konferenzüberblick: Kommende Veranstaltungen zur Webentwicklung im ersten Halbjahr 2016",
		"url": "http://heise.de/-3090251",
		"excerpt": "Auch dieses Jahr stehen wieder eine Reihe interessanter Veranstaltungen zu den Themen HTML, CSS und JavaScript an. Die folgende Auflistung zeigt einige kommende Events innerhalb Deutschlands für das erste Halbjahr 2016.",
		"tags": ["JS", "CSS", "HTML"]
	},
	{
		"issue": "heise Developer",
		"year": 2016,
		"month": 2,
		"title": "Features von übermorgen: ES2016",
		"url": "http://heise.de/-3089503",
		"excerpt": "Die Features, die offiziell Einzug in die nächste Version des ECMAScript-Standards Einzug halten werden, sind seit letzter Woche final. Dazu gehören alle Features, die bis zum 28.01.2016 Stage 4 erreicht hatten, was – nun ja – lediglich zwei neue sind: die neue Array-Methode includes() sowie der Potenzierungs-Operator. Gute Gelegenheit, sich beides einmal kurz anzuschauen.",
		"tags": ["JS"]
	},
	{
		"issue": "heise Developer",
		"year": 2016,
		"month": 1,
		"title": "Patterns und Best Practices in JavaScript: Typüberprüfungen continued",
		"url": "http://heise.de/-3086830",
		"excerpt": "Im vorigen Artikel dieser Serie hatten wir gesehen, dass der instanceof-Operator in JavaScript in einigen Fällen problematisch sein kann, nämlich immer dann, wenn man mit mehreren Versionen der gleichen \"Klasse\" (bzw. des gleichen Prototypen) arbeitet. In diesem Artikel nun möchte ich auf diese Problematik zurückkommen und einige Lösungsansätze skizzieren.",
		"tags": ["JS"]
	},
	{
		"issue": "heise Developer",
		"year": 2016,
		"month": 1,
		"title": "Ich kenne was, was Du nicht kennst: minimongo",
		"url": "http://heise.de/-3065475",
		"excerpt": "MongoDB ist eine beliebte und verbreitete NoSQL-Datenbank. Wer ihre API nutzen, aber lieber lokal mit IndexedDB oder LocalStorage arbeiten möchte, kann auf das Node.js-Modul minimongo zurückgreifen, das einen (kleinen) Teil der MongoDB-API implementiert, im Hintergrund aber unter anderem besagte Speichermöglichkeiten nutzen kann.",
		"tags": ["JS"]
	},
	{
		"issue": "heise Developer",
		"year": 2015,
		"month": 12,
		"title": "Patterns und Best Practices in JavaScript: Typüberprüfungen",
		"url": "http://heise.de/-3032956",
		"excerpt": "In einem meiner letzten Blogbeiträge hatte ich erwähnt, dass Typüberprüfungen in JavaScript nicht ganz ohne sind. Was war noch schnell der Unterschied zwischen typeof und instanceof? Und funktionieren beide Operatoren wirklich so, wie man das selbst auch gedacht hat? Fragen, mit denen sich JavaScript-Entwickler irgendwann beschäftigen sollten. Besser heute als morgen.",
		"tags": ["JS"]
	},
	{
		"issue": "heise Developer",
		"year": 2015,
		"month": 11,
		"title": "Features von übermorgen: CSS3 und runde Displays",
		"url": "http://heise.de/-2878394",
		"excerpt": "Nachdem mit Smartwatches nun der nächste Trend in den Startlöchern steht, sehen sich Webentwickler bereits vor einer neuen Herausforderung: Wie kann die Oberfläche einer Webanwendung an runde Displays angepasst werden? Responsive Design bezieht sich derzeit ja schließlich immer auf rechteckige Displays.",
		"tags": ["CSS"]
	},
	{
		"issue": "heise Developer",
		"year": 2015,
		"month": 10,
		"title": "Ich kenne was, was Du nicht kennst: check-types",
		"url": "http://heise.de/-2817547",
		"excerpt": "Insbesondere Sprachneulinge dürften anfangs über den ein oder anderen typbedingten Fehler bei der JavaScript-Entwicklung stoßen. Typüberprüfungen in JavaScript sind nämlich so eine Sache: Was war nochmal der Unterschied zwischen dem typeof- und dem instanceof-Operator? Und was der Unterschied zwischen null und undefined? Warum gibt der typeof-Operator für Arrays und reguläre Ausdrücke den Wert \"object\" zurück, für Funktionen aber den Wert \"function\" (obwohl diese ja eigentlich auch Objekte sind)?",
		"tags": ["JS"]
	},
	{
		"issue": "heise Developer",
		"year": 2015,
		"month": 9,
		"title": "Konferenzüberblick: Kommende Veranstaltungen zum Thema Webentwicklung in 2015",
		"url": "http://heise.de/-2819234",
		"excerpt": "Nachdem es dieses Jahr schon einige großartige Events zu den Themen HTML, CSS und JavaScript gegeben hat, stehen weitere noch kurz vor der Tür. Im Folgenden dazu eine Liste der noch im Jahr 2015 kommenden Veranstaltungen innerhalb Deutschlands.",
		"tags": ["JS", "CSS", "HTML"]
	},
	{
		"issue": "heise Developer",
		"year": 2015,
		"month": 9,
		"title": "Patterns und Best Practices in JavaScript: Der Umgang mit Callback-Funktionen",
		"url": "http://heise.de/-2806593",
		"excerpt": "Wie in jeder anderen Programmiersprache gibt es auch für JavaScript eine Reihe von Best Practices und damit einhergehenden Bad Practices. Aufgrund seiner dynamischen Eigenschaften hat JavaScript zudem verschiedene Tücken, die Entwickler kennen sollten. Diese neue Artikelreihe soll sich mit all dem beschäftigen: Best Practices, Bad Practices und den Eigenarten der Sprache. Den Anfang machen Callback-Funktionen.",
		"tags": ["JS"]
	},
	{
		"issue": "heise Developer",
		"year": 2015,
		"month": 8,
		"title": "Features von übermorgen: ES7 Observer",
		"url": "http://heise.de/-2777709",
		"excerpt": "Ein Feature, welches es nicht mehr in den ES6-Standard geschafft, aber mittlerweile den Status eines Draft inne hat und somit für eine Aufnahme in ES7 ganz gut positioniert scheint, ist die Methode Object.observe().",
		"tags": ["JS"]
	},
	{
		"issue": "heise Developer",
		"year": 2015,
		"month": 6,
		"title": "Ich kenne was, was Du nicht kennst: Later",
		"url": "http://heise.de/-2729692",
		"excerpt": "Ein interessantes Modul für das Definieren von Cron-Jobs oder ganz allgemein für das zeitgesteuerte Ausführen bestimmter Aufgaben ist Later. Es lässt sich mit Node.js und auch im Browser einsetzen und benötigt dabei keine Datenbank.",
		"tags": ["JS"]
	},
	{
		"issue": "heise Developer",
		"year": 2015,
		"month": 6,
		"title": "Mouse Events und Touch Events, aber einfacher: die Pointer Events API",
		"url": "http://heise.de/-2648006",
		"excerpt": "Im Februar hat das W3C die Pointer Events API als Recommendation veröffentlicht. Dies und die Tatsache, dass sich Browserhersteller Google (nach einigem Hin und Her in der Vergangenheit) nun doch dazu entschieden hat, die Pointer Events API zu implementieren (und nicht wie Apple auf der Touch Events API beharrt), sei zum Anlass genommen, noch einmal kurz zu zeigen, was es mit ihr auf sich hat.",
		"tags": ["JS"]
	},
	{
		"issue": "heise Developer",
		"year": 2015,
		"month": 5,
		"title": "Ich kenne was, was Du nicht kennst: Light Weight Image Processor",
		"url": "http://heise.de/-2651977",
		"excerpt": "Der Nachteil bei den meisten Node.js-Modulen für die Bildbearbeitung ist, dass sie lediglich Wrapper um bereits existierende Tools sind und somit zusätzliche Installationen voraussetzen. Doch nicht immer möchte man solche Abhängigkeiten haben. Hier setzt LWIP an.",
		"tags": ["JS"]
	},
	{
		"issue": "heise Developer",
		"year": 2015,
		"month": 5,
		"title": "AJAX, aber einfacher: Die neue Fetch API",
		"url": "http://heise.de/-2642427",
		"excerpt": "AJAX-basierte Anfragen lassen sich, wenn man keine Hilfsbibliotheken wie jQuery hinzunimmt, eher umständlich formulieren. Die neue Fetch API verspricht hier eine einfach zu bedienende, auf Promises basierende Alternative.",
		"tags": ["JS"]
	},
	{
		"issue": "heise Developer",
		"year": 2015,
		"month": 5,
		"title": "Features von übermorgen: ES7 Decorators",
		"url": "http://heise.de/-2633730",
		"excerpt": "Während ES6 in den Startlöchern steht, gehen bereits fleißig weitere Beiträge für ES7 ein. Einer davon ist der vor Kurzem veröffentlichte Vorschlag der sogenannten Decorators von Yehuda Katz.",
		"tags": ["JS"]
	},
	{
		"issue": "heise Developer",
		"year": 2015,
		"month": 4,
		"title": "Ich kenne was, was Du nicht kennst: MustBe",
		"url": "http://heise.de/-2601697",
		"excerpt": "Im ersten Teil der \"Ich kenne was...\"-Reihe ging es darum, wie man mit Hilfe des Moduls Passport einer auf Express basierenden Webanwendung Authentifizierung beibringt. Nun soll ein weiteres Modul das Ganze um Autorisierung erweitern.",
		"tags": ["JS"]
	},
	{
		"issue": "heise Developer",
		"year": 2015,
		"month": 3,
		"title": "Features von übermorgen: Natives MVC in HTML6?",
		"url": "http://heise.de/-2585841",
		"excerpt": "Sollte HTML5 doch nicht das Ende der Fahnenstange gewesen sein? Auf der Mailing-Liste der entsprechenden Arbeitsgruppe macht man sich Gedanken um Features eines eventuellen Nachfolgers.",
		"tags": ["HTML"]
	},
	{
		"issue": "heise Developer",
		"year": 2015,
		"month": 3,
		"title": "Features von übermorgen: CSS3 Selectors API Level 4",
		"url": "http://heise.de/-2575782",
		"excerpt": "Während die CSS3 Selectors Level 3 schon seit geraumer Zeit eine W3C Recommendation ist, arbeitet man beim W3C derzeit noch an Level 4 der Selectors API. Der aktuelle Editor's Draft listet einige interessante neue Features, die Webentwicklern die Arbeit mit CSS erleichtern sollen. Dieser Beitrag stellt einige davon vor.",
		"tags": ["CSS"]
	},
	{
		"issue": "heise Developer",
		"year": 2015,
		"month": 3,
		"title": "Features von übermorgen: ES7 Async Functions",
		"url": "http://heise.de/-2561894",
		"excerpt": "Der Großteil der Web- und JavaScript-Entwickler wartet gespannt auf die endgültige Verabschiedung des ECMAScript6-Standards beziehungsweise den vollständigen Browser-Support, doch nur die wenigsten dürften sich mit Features beschäftigt haben, die für die übernächste Version ES7 auf dem Plan stehen. Gute Gelegenheit, sich ein Wesentliches davon einmal anzuschauen: die sogenannten Async Functions.",
		"tags": ["JS"]
	},
	{
		"issue": "heise Developer",
		"year": 2015,
		"month": 2,
		"title": "Tales from the Web side",
		"url": "http://heise.de/-2553302",
		"excerpt": "Tales from the Crypt ist eine US-amerikanische Horrorserie der frühen 90er Jahre. Ich muss zugeben, ich habe die Serie nie gesehen (und mich auch nicht bei der Namenswahl des Blogs dadurch inspirieren lassen), bin aber zuversichtlich, dass dieser Blog nicht ganz so schrecklich und angsteinflößend sein wird, wie die dort verfilmten Kurzgeschichten.",
		"tags": ["JS", "CSS", "HTML"]
	}
];

const ArticlesBlogTemplate = ({ data }: Props) => {
  const { title: siteTitle, subtitle: siteSubtitle } = useSiteMetadata();
  const { html: pageBody } = data.markdownRemark;
  const { title: pageTitle, description: pageDescription } = data.markdownRemark.frontmatter;
  const metaDescription = pageDescription !== null ? pageDescription : siteSubtitle;

  return (
    <Layout title={`${pageTitle} - ${siteTitle}`} description={metaDescription}>
      <Sidebar />
      <Page title={pageTitle}>
        <div dangerouslySetInnerHTML={{ __html: pageBody }} />
		{
			articles.map((article) => (
				<div>
					<h3>
						<a href={article.url} target="_blank">{article.title}</a>
					</h3>
					<small><strong>{article.issue}</strong> {article.month}/{article.year}</small>
					<div>{article.excerpt}</div>
					{/* {
						article.tags.map((tag) => (
							<span class="badge">
								{tag}
							</span>
						))
					} */}
				</div>
			))
		}
      </Page>
    </Layout>
  );
};

export const query = graphql`
  query ArticlesBlogBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date
        description
      }
    }
  }
`;

export default ArticlesBlogTemplate;
